import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

function Row(props) {
  const { data } = props;
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell component="th" scope="row">
          {data.status === 1 ? (
            <span className="bg-warningT warning-color status">Due</span>
          ) : data.status === 2 ? (
            <span className="bg-successXS success-color status">Paid</span>
          ) : (
            <span className="bg-errorSM error-color status">Reverse</span>
          )}
        </TableCell>
        <TableCell align="right">$ {data.amount}</TableCell>
        <TableCell align="right">$ {data.paidAmt}</TableCell>
        <TableCell align="right">$ {data.amount - data.paidAmt}</TableCell>
        <TableCell align="right">{data.issueDate}</TableCell>
        <TableCell align="right">{data.dueDate}</TableCell>
        <TableCell align="right">{data.paidDate}</TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {data.invLateFees.length > 0 ? (
              <Box sx={{ margin: 1 }}>
                <Typography
                  variant="h6"
                  gutterBottom
                  className="gray-color fw-4"
                >
                  Late Fee
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Paid Date</TableCell>
                      <TableCell>Charge</TableCell>
                      <TableCell align="right">Paid</TableCell>
                      <TableCell align="right">Due</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.invLateFees.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell component="th" scope="row">
                          {item.createDate}
                        </TableCell>
                        <TableCell>{item.lastPaidDate}</TableCell>
                        <TableCell>$ {item.amount}</TableCell>
                        <TableCell align="right">$ {item.paidAmt}</TableCell>
                        <TableCell align="right">
                          $ {item.amount - item.paidAmt}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            ) : (
              <h4 className="p-10 gray-color fs-16 fw-4">No late fee</h4>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function PayHistory({ payments }) {
  React.useEffect(() => {
    return () => {};
  }, []);

  return (
    <>
      <h4 className="fs-18 gray-color mb-10 fw-4">
        Total Due:&nbsp;${" "}
        {payments
          .map(
            (j) =>
              j.amount -
              j.paidAmt +
              j.invLateFees
                .map((x) => x.amount - x.paidAmt)
                .reduce((x, y) => x + y, 0)
          )
          .reduce((x, y) => x + y, 0)
          .toLocaleString("en-US", { maximumFractionDigits: 2 })}
      </h4>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Status</TableCell>
              <TableCell align="right">Charge</TableCell>
              <TableCell align="right">Paid</TableCell>
              <TableCell align="right">Due</TableCell>
              <TableCell align="right">Issue Date</TableCell>
              <TableCell align="right">Due Date</TableCell>
              <TableCell align="right">Paid Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payments.map((data) => (
              <Row key={data.id} data={data} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
